<template>
    <section v-if="getActiveStore.role != 'STAFF'">
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">PRODUCTS</div>
                <!-- <div class="level-right has-text-centered">
                    <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button>
                </div> -->
            </div>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="columns" v-if="isEditable && productsUpdated.length > 0">
            <div class="column">
                &nbsp;&nbsp;
                <b-switch
                    :true-value="true"
                    :false-value="false"
                    size="is-small"
                    v-model="showOnlyUpdated"
                    @input="filtersChanged"
                    type="is-success">
                    Show Updated Products
                </b-switch>
                &nbsp;
                <b-tag type="is-success"><span class="is-size-6" >{{ productsUpdated.length }}</span></b-tag>
            </div>
            <VeeSavePane
                :row1="'Price Updated for ' + productsUpdated.length + ' Products.'"
                :cancelEnable="productsUpdated.length > 0"
                :saveEnable="productsUpdated.length > 0"
                :isEditing="productsUpdated.length > 0"
                @cancel-edit="cancelAllUpdates()"
                @save-edit="saveAllUpdates()"
                >

            </VeeSavePane>
        </div>

        <div class="panel-block">
            <b-table class="control" 
                :data='records'
                :paginated="isPaginated"
                :per-page="perPage"
                :current.sync="page"
                backend-pagination
                backend-sorting
                :backend-filtering="backendFiltering"
                :debounce-search="searchStartMilliSecs"
                :total="totalRecords"
                @page-change="onPageChange"
                @sort="onColumnSort"
                paginationPosition="both"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                :row-class="getRowClass"
                :default-sort="sortField"
                :defaultSortDirection='sortOrder'
                @filters-change="filtersChanged"
                >

            <template>
                <b-table-column numeric field="reset" label=""  v-slot="props">
                    <b-button outlined rounded type="is-primary is-small"
                        v-show="isEditable"
                        @click="doAction(props.row)"
                        tabindex="-1"
                        icon-right="far fa-edit" />
                </b-table-column>
                <b-table-column field="" v-slot="props">
                    <b-button outlined rounded type="is-danger is-small"
                            v-show="isProductUpdated(props.row)"
                            @click="resetUpdate(props.row)"
                            tabindex="-1"
                            icon-right="far fa-trash" />
                </b-table-column>
                <b-table-column field="upc" label="UPC" searchable sortable sorticon v-slot="props">
                    {{ props.row.upc }}-{{ zeroPad(props.row.upcModifier,3) }}
                </b-table-column>
                
                <b-table-column class="is-2" field="barCode"  v-slot="props" label="Barcode" v-if="isShowBarcode">
                    <vue-barcode 
                        v-bind:value="props.row.upc" 
                        height="20" 
                        width="1" 
                        displayValue="false">
                        N/A
                    </vue-barcode>
                </b-table-column>                
                <b-table-column field="name" label="Name" searchable sortable sorticon  v-slot="props">
                   {{ props.row.name }}
                </b-table-column>
                <b-table-column field="departments.name" label="Dept" searchable sortable sorticon  v-slot="props">
                   {{ ( props.row.departments ? props.row.departments.name : 'n/a' ) }}
                </b-table-column>
                <!-- <b-table-column field="departments.storeMapping" label="Mapping" searchable sortable sorticon  v-slot="props">
                   {{ ( props.row.departments ? props.row.departments.storeMapping : 'n/a' ) }}
                </b-table-column> -->
                <!-- <b-table-column numeric  field="averageCost" label="Cost" v-slot="props">
                    {{ props.row.costCurrent | formatCurrency }} 
                </b-table-column> -->

                <b-table-column numeric field="averageCost" label="Purchase Cost" v-slot="props" sortable sorticon v-if="isEditable" class="pbu-into2">
                    <VeeInput 
                        class="narrow-input"
                        type="currency"
                        v-model="props.row.averageCost"
                        name="averageCost"
                        :value="props.row.averageCost"
                        :countryCurrencyCode="countryCurrencyCode"
                        :editable="isEditable"
                        @input="updateProduct(props.row)"
                        />
                </b-table-column>
                <b-table-column numeric  field="suggestedSellPrice" label="Suggested Price" sortable sorticon v-slot="props" >
                    <!-- <span :class="suggestedPriceClass(props.row)">
                        {{ props.row.suggestedSellPrice | formatCurrency }} 
                    </span> -->

                    <b-tooltip 
                            type="is-primary">
                            <template v-slot:content >
                                Dept: {{ props.row.departments.percentage | formatNumber}}%
                            </template>
                            <span class="tooltip" :class="{'bad-price' : currentMarginPerc(props.row) < 0,  'has-text-warning-dark' : currentMarginPerc(props.row) >= 0}">  
                                {{ props.row.suggestedSellPrice | formatCurrency }}
                            </span>
                        </b-tooltip>
                </b-table-column> 
                <b-table-column numeric field="priceCurrent" label="Current Price"  v-slot="props" class="pbu-into3">
                    {{ props.row.priceCurrent | formatCurrency }}
                </b-table-column>
                <b-table-column numeric field="price" label="New Price" v-slot="props" sortable sorticon v-if="isEditable">
                    <VeeInput 
                        class="narrow-input"
                        :class="currentMarginPerc(props.row) < 0 ? 'bad-price' : 'good-price'"
                        type="currency"
                        v-model="props.row.price"
                        name="price"
                        :value="props.row.price"
                        :countryCurrencyCode="countryCurrencyCode"
                        :editable="isEditable"
                        @input="updateProduct(props.row)"
                        />
                </b-table-column>
                <b-table-column numeric field="departments.percentage" label="Price Margin" sortable sorticon  v-slot="props">  
                        <b-tooltip 
                            v-if="currentMarginPerc(props.row) != props.row.departments.percentage"
                            type="is-primary">
                            <template v-slot:content >
                                Dept: {{ props.row.departments.percentage | formatNumber}}%
                            </template>
                            <span class="tooltip" :class="{'bad-price' : currentMarginPerc(props.row) < 0,  'has-text-warning-dark' : currentMarginPerc(props.row) >= 0}">  
                                {{ currentMarginPerc(props.row) | formatNumber }}%
                            </span>
                        </b-tooltip>

                        <span v-else class="good-price">
                            {{ currentMarginPerc(props.row) | formatNumber}}%
                        </span>
                </b-table-column>
                <!-- <b-table-column field="active" label="Active" v-slot="props">
                    <i class="fas fa-check-circle  has-text-success" v-if="props.row.active"></i>
                    <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                </b-table-column> -->
            </template>
            
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

            <template slot="top-left">
                <div  class="is-size-6">
                    Products per page: 
                    <select class="is-size-6" name="perPage" v-model="perPage" @change="onPageChange(1)">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>                   
                </div>
            </template>

            <template slot="header">
                
            </template>
            <template slot="footer">
                
            </template>    

        </b-table>  

        </div>

    </div>

    <!-- <product-details 
        :isShowDialog="isShowDialog"
        :product="product"
        :allowEdit="allowEdit"
        @hide-dialog="toggleDialog">
    </product-details> -->

    <product-update-form
        ref="productUpdateForm"
        :isShowDialog="isShowDialog"
        :allowEdit="allowEdit"
        :scannedProduct="scannedProduct"
        :addingNewProduct="addingNewProduct"
        :departments="departments"
        :posConfig="posConfig"
        @clear-idchecks="clearIdCheck"
        @suggested-price="suggestedPrice"
        @dept-profit-percentage="deptProfitPercentage"
        @cancel-product-form="cancelProductForm"
        @opened="dialogOpened"
        @save-updated-product="updateProductWithForm"
        >

    </product-update-form>

    </section>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading'
import VeeInput from "../../../../components/app/VeeInput"
import VeeSavePane from "../../../../components/app/VeeSavePane"
import * as introJs from '../../../../assets/introjs/intro.min.js'
import VueBarcode from 'vue-barcode'

import axios from 'axios'

import ProductUpdateForm from "./ProductUpdateForm"

export default {

    components: {
        DataLoading,
        VeeInput,
        VeeSavePane,
        // ToolTip,
        ProductUpdateForm,
        VueBarcode
    },

    metaInfo: {
        title: 'Products'
    },

    data() {
        return {
            user: [],
            records: [],
            isPaginated: true,
            perPage: 50,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            sortField: 'name',
            sortOrder: 'asc',
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            addingNewProduct: false,
            departments: [],
            posConfig: {},
            suggestedPrice: 0,
            scannedProduct: {
                "taxRatesIdArr": [],
                "idCheckIdArr": []
            },
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            isShowPDFDialog: false,
            backendFiltering: true,
            searchName: "",
            searchUPC: "",
            searchDesc: "",
            searchDept: "",
            searchMap: "",
            searchStartMilliSecs: 1000,
            product: {"departments": {}, priceCurrent: 0},
            productsUpdated: [],
            allowEdit: true,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            isEditable: true,
            showOnlyUpdated: false,
            trueValue: true,
            falseValue: false,
            isShowBarcode: false,
            introSteps: [{
                    title: 'Price Book Updates',
                    intro: 'Easy to use and powerful Price Book Updates features.'
                },
                {
                    title: 'Edit Button',
                    element: document.querySelector('pbu-into1'),
                    intro: 'Search Name, Department, UPC, etc and click on edit.'
                },
                {
                    title: 'Cost Update!',
                    element: document.querySelector('pbu-into2'),
                    intro: 'Just want to update cost? Enter directly on each product without opening edit form for each product.'
                },
                {
                    title: 'Retail Price Update!',
                    element: document.querySelector('pbu-into3'),
                    intro: 'Just want to update cost? Enter directly on each product without opening edit form for each product.'
                }]
        }
        
    },

    methods: {

        fetchRecords() {

            if (this.user.activeStore) {
                var showPage = this.page - 1
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/products?withInventory=false&size=" + this.perPage + "&page=" + showPage +
                        "&sort=" + this.sortField + "," + this.sortOrder 
                        if (this.searchName) {
                            url = url + "&name=" + this.searchName
                        }
                        if (this.searchUPC) {
                            url = url + "&upc=" + this.searchUPC
                        }
                        if (this.searchDesc) {
                            url = url + "&description=" + this.searchDesc
                        }
                        if (this.searchDept) {
                            url = url + "&departments.name=" + this.searchDept
                        }
                        if (this.searchMap) {
                            url = url + "&departments.storeMapping=" + this.searchMap
                        }
                        
                                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data.content
                        this.records.forEach(product => {

                            if (product.taxRateSysIds) {
                                product.taxRatesIdArr = this.sysIdListToIdArray(this.posConfig.taxRates,product.taxRateSysIds)
                                product.taxRatesArrCurrent = product.taxRatesIdArr
                                product.isTaxable = true
                            } else {
                                product.taxRatesIdArr = []
                                product.taxRatesArrCurrent = []
                                product.isTaxable = false
                            }

                            if (product.idCheckSysIds) {
                                product.idCheckIdArr = this.sysIdListToIdArray(this.posConfig.ageValidations,product.idCheckSysIds)
                                product.isAgeValidation = true
                            } else {
                                product.idCheckIdArr = []
                                product.isAgeValidation = false
                            }

                            var index = this.productsUpdated.findIndex((map) => map.upc === product.upc)
                            if (index > -1) {
                                product.nameCurrent = this.productsUpdated[index].nameCurrent
                                product.priceCurrent = this.productsUpdated[index].priceCurrent
                                product.price = this.productsUpdated[index].price
                                product.deptCurrent = this.productsUpdated[index].departments
                                this.productsUpdated[index].foodStamp = (this.productsUpdated[index].foodStamp ? this.productsUpdated[index].foodStamp : false)
                                product.foodStampCurrent = this.productsUpdated[index].foodStamp
                                product.taxRatesArrCurrent = this.productsUpdated[index].taxRatesIdArr
                                product.idCheckIdArrCurrent = this.productsUpdated[index].idCheckIdArr
                                product.isTaxable = (this.productsUpdated[index].taxRatesIdArr.length > 0 ? true : false)
                                product.isAgeValidation = (this.productsUpdated[index].idCheckIdArr.length > 0 ? true : false)
                            } else {
                                product.nameCurrent = product.name
                                product.priceCurrent = product.price
                                product.costCurrent = product.averageCost
                                product.deptCurrent = product.departments
                                product.foodStamp = (product.foodStamp ? product.foodStamp : false)
                                product.foodStampCurrent = product.foodStamp
                                product.taxRatesArrCurrent = product.taxRatesIdArr
                                product.idCheckIdArrCurrent = product.idCheckIdArr
                                product.isTaxable = (product.taxRatesIdArr && product.taxRatesIdArr.length > 0 ? true : false)
                                product.isAgeValidation = (product.idCheckIdArr && product.idCheckIdArr.length > 0 ? true : false)
                            }
                            if (product.averageCost && product.departments) {
                                product.suggestedSellPrice = this.calculateMercandiseSalePrice(product.averageCost,product.departments.percentage)
                            } else {
                                product.suggestedSellPrice = 0
                            }
                        })
                        this.totalPages = response.data.data.totalPages
                        this.page = response.data.data.number
                        this.perPage = response.data.data.size
                        this.totalRecords = response.data.data.totalElements
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        // if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        // this.isLoading = false
                        // if (err.response.status === 403) {
                        //     alert('Session Expired. Please log in again!')
                        //     UserStore.commit('userLogout')
                        // }
                        console.log(err)
                    })
            }
            this.isLoading = false
            
        },

        async fetchDepartments() {
            this.departments = []
            var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + this.user.activeStore +
                    "/departments"
            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    this.departments = this.sortArrayByProp(response.data.data,"name")
                })
        },

        async fetchPosConfig() {
            this.posConfig = {}
            var url = process.env.VUE_APP_API_ROOT_URL + "stores/"  + this.user.activeStore + "/posConfig"

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    this.posConfig = response.data.data
                    this.posConfig.ageValidations.forEach(age => {
                        age.sysid = age.systemId
                    })
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        message: "POS Config Data Invalid" + err,
                            type: 'is-danger'
                    }) 

                })


        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        doAction(row) {
            this.scannedProduct = row 
            
            if (this.scannedProduct.blueLawSysIds) {
                this.scannedProduct.blueLawIdsArr = this.sysIdListToIdArray(this.posConfig.blueLaws,this.scannedProduct.blueLawSysIds)
            } else {
                this.scannedProduct.blueLawIdsArr = []
            }

            if (this.scannedProduct.feeAddr) {
                this.scannedProduct.blueLawIdsArr = this.sysIdListToIdArray(this.posConfig.blueLaws,this.scannedProduct.blueLawSysIds)
            } else {
                this.scannedProduct.blueLawIdsArr = []
            }
            this.suggestedPrice = (row.suggestedSellPrice ? row.suggestedSellPrice : 0)

            this.$refs.productUpdateForm.openDialog()
            this.toggleDialog()
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },

        cancelProductForm() {
            this.scannedProduct = Object.assign({}, this.emptyProduct)
            this.resetUpdate(this.scannedProduct)
            this.isShowDialog=false
        },
   
        getEditToolTip(delname) {
            return 'Edit ' + delname
        },

        getDeleteToolTip(delname) {
            return 'Delete ' + delname
        },

        statusClass() {
            return "has-background-primary"
        },

        onColumnSort(field,order) {
            this.sortField = field
            this.sortOrder = order
            if (!this.showOnlyUpdated) {
                this.fetchRecords()
            }
        },

        onPageChange(page) {
            this.page = page
            if (!this.showOnlyUpdated) {
                this.fetchRecords()
            }
        },        

        filtersChanged(event) {
            this.isLoading = true
            this.records = []
            if (this.showOnlyUpdated) {
                this.isPaginated = false
                this.records = this.productsUpdated
                this.totalRecords = this.productsUpdated.length
                this.totalPages = this.totalRecords / this.perPage
            } else {
                this.isPaginated = true
                this.searchName = event.name
                this.searchUPC = event.upc
                this.searchDesc = event.description
                this.searchDept = event["departments.name"]
                this.searchMap = event["departments.storeMapping"]
                this.fetchRecords()
            }
            this.isLoading = false
        },

        doProductAction(action, product) {
            this.product = product
            this.toggleDialog()
        },

        updateProductWithForm() {
            this.scannedProduct.taxRateSysIds = this.idArrayToSysIdList(this.posConfig.taxRates,this.scannedProduct.taxRatesIdArr)
            this.scannedProduct.idCheckSysIds = this.idArrayToSysIdList(this.posConfig.taxRates,this.scannedProduct.idCheckIdArr)
            var row = this.records.find(row => row.upc === this.scannedProduct.upc)
            if (row) {
                this.updateProduct(row)
            } else {
                this.$buefy.toast.open({
                message: "Invalid UPC Code: " + this.scannedProduct.upc +". Update Failed.",
                    type: 'is-danger'
                })
            }
            this.toggleDialog()
        },

        updateProduct(row) {
            row.suggestedSellPrice = this.calculateMercandiseSalePrice(row.averageCost,row.departments.percentage)
            var index = this.productsUpdated.findIndex((map) => map.upc === row.upc)
            if (index > -1) {
                if (this.isProductUpdated(row)) {
                    this.productsUpdated[index] = row
                } else {
                    this.productsUpdated.splice(index, 1)
                }
            } else {
                if (this.isProductUpdated(row)) {
                    this.productsUpdated.push(row)
                    index = this.productsUpdated.length - 1
                }
            }
        },

        // priceUpdated(row) {            
        //     var index = this.productsUpdated.findIndex((map) => map.upc === row.upc)
        //     if (index > -1) {
        //         if (row.price != row.priceCurrent) {
        //             this.productsUpdated[index] = row
        //         } else {
        //             this.productsUpdated.splice(index, 1)
        //         }
        //     } else {
        //         if (row.price != row.priceCurrent) {
        //             this.productsUpdated.push(row)
        //             index = this.productsUpdated.length - 1
        //         }
        //     }
        // },

        resetUpdate(row) {
            var index = this.records.findIndex((map) => map.upc === row.upc)
            if (index > -1) {
                if (this.isProductUpdated(row)) {
                    this.records[index].price = this.records[index].priceCurrent
                    this.records[index].averageCost = this.records[index].costCurrent
                    this.records[index].departments = this.records[index].deptCurrent
                    this.records[index].idCheckIdArr = this.records[index].idCheckIdArrCurrent
                    this.records[index].taxRatesIdArr = this.records[index].taxRatesArrCurrent
                    var indexUpd = this.productsUpdated.findIndex((map) => map.upc === row.upc)
                    if (indexUpd > -1) {
                        this.productsUpdated.splice(indexUpd, 1)
                    }
                }
            }
            if (this.productsUpdated.length == 0) {
                this.cancelAllUpdates()
            }
        },

        cancelAllUpdates() {
            this.productsUpdated = []
            this.records = []
            this.showOnlyUpdated = false
            this.isPaginated = true
            this.fetchRecords()
        },

        saveAllUpdates() {
            var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore + "/products"
            axios.put(url,this.productsUpdated,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken,
                    'correlation-id': this.$uuid.v1()
                },                   
            })
            .then(response => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Updated Prices Queued.  POS will be updated within 15 mins.'+response.status,
                    position: 'is-top',
                    type: 'is-success'
                })
                this.productsUpdated = []
                this.records = []
                this.showOnlyUpdated = false
                this.isPaginated = true
                this.fetchRecords()
            })
            .catch(err => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Failed to Save. Cleared all updates. '+err,
                    position: 'is-top',
                    type: 'is-danger'
                })
            })

        },

        suggestedPriceClass(row) {
            if (!row.suggestedSellPrice) {
                return "has-text-warning-dark"
            } 
            if (row.suggestedSellPrice > row.price) {
                return "has-text-danger-dark"
            } 
            return "has-text-success"
        },

        getRowClass(row) {
            if (this.isProductUpdated(row)) {
                return 'has-background-warning-light'
            }
        },
        
        suggestedProfitClass(row) {
            if (this.currentMarginPerc(row) < 0) {
                return "has-text-danger-dark"
            } 
            return "" 
        },

        currentMarginPerc(row) {
            if (!row.departments) row.departments = []
            return this.calculateMerchandiseProfit(row.averageCost,row.price,row.departments.percentage)
        },

        isProductUpdated(row) {
            return row.price != row.priceCurrent || 
                row.averageCost != row.costCurrent ||
                row.idCheckIdArr != row.idCheckIdArrCurrent ||
                row.taxRatesIdArr != row.taxRatesArrCurrent ||
                row.foodStamp != row.foodStampCurrent ||
                (row.departments && row.deptCurrent && row.departments.id != row.deptCurrent.id)
        },

        clearIdCheck() {
            this.scannedProduct.idCheckSysIds = ""
            this.scannedProduct.idCheckIdArr = []
            console.log(this.scannedProduct.idCheckIdArr)
        },

        dialogOpened() {
            introJs().setOptions({
                steps: this.introSteps,
                tooltipClass: 'introJsTooltip'
            }).start()
        }

    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        deptProfitPercentage() {
            // var perc =  (this.scannedProduct && this.scannedProduct.departments ? this.scannedProduct.departments.percentage : 0)
            // if (!perc) {
            //     return 0
            // }
            // return perc
            return 0
        },


    }, 

    mounted() {

        this.user = this.getUser()
        this.fetchDepartments()
        this.fetchPosConfig()
        this.fetchRecords()
        
    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}

.narrow-input {
  width: 70px;
}

.bad-price input[type="text"]{
    color: rgb(172, 1, 1);
}

.bad-price {
    color:  rgb(172, 1, 1);
}
.good-price {
    color: green;
}

.disabledsearch {
    pointer-events:auto;
    color:#AAA;
    background:#F5F5F5;
}
</style>